import { gql } from 'graphql-request'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { ActionNetworkForm } from '../../ActionNetworkForm'

export type ActionNetworkPlainFormZoneType = DynamicBlockZone<
  'ComponentFormActionNetworkPlain',
  {
    formUrl: string
  }
>

export const ActionNetworkPlainFormZoneFragment = gql`
... on ComponentFormActionNetworkPlain {
  __typename
  formUrl
}
`

const ActionNetworkPlainFormZone = (props: ActionNetworkPlainFormZoneType) => {
  return (
    <ActionNetworkForm formURL={props.formUrl}></ActionNetworkForm>
  )
}

export default ActionNetworkPlainFormZone
