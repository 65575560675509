import { gql } from 'graphql-request'
import { DynamicBlockZone } from '../../../lib/strapi/dynamicBlockZones'
import { Heading, Style, Variant } from '../../Heading'
import { TextBlock } from '../../TextBlock'
import { AthleteFeature, AthleteData } from '../../AthleteFeature'
import { ImageType, LinkType } from '../../../lib/strapi/responseTypes'
import { ThumbnailCard } from '../../ThumbnailCard'
import { Link, LinkVariant } from '../../Link'
import { Markdown } from '../../Markdown'

export type GridCardZoneType = DynamicBlockZone<
  'ComponentGridGridCard',
  {
    title: string
    subTitle: string
    body: string
    cardImage: ImageType
    link: LinkType
  }
>

export const GridCardZoneFragment = gql`
... on ComponentGridGridCard {
    __typename
    title
    subTitle
    body
    link {
      href
      label
      variant
    }
    cardImage {
       data {
attributes {
  alternativeText
  url
}
}
    }
  }
`

const GridCardZone = (props: GridCardZoneType) => {

  if (!!props.link) {
    props.link 
  }

  return (
    <div className="flex flex-1">
      { !!props.link && (
                  <ThumbnailCard
                  title={props.title}
                  subtitle={props.subTitle}
                  alt={props.cardImage.data.attributes.alternativeText}
                  imgSrc={props.cardImage.data.attributes.url}
                  link={
                    <Link
                      variant={LinkVariant.FORWARD}
                      href={props.link.href}
                      label={props.link.label}
                      tabIndex={-1}
                      allowWrap
                    />
                  }
                >
                  <Markdown source={props.body} isForBlog={false} />
                </ThumbnailCard>
      )
      }
      {
        props.link == null && (
          <ThumbnailCard
          title={props.title}
          subtitle={props.subTitle}
          alt={props.cardImage.data.attributes.alternativeText}
          imgSrc={props.cardImage.data.attributes.url}
        >
         <Markdown source={props.body} isForBlog={false} />
        </ThumbnailCard>
        )
      }

    </div>
  )
}

export default GridCardZone
