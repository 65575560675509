import Script from 'next/script';

export interface ActionNetworkFormProps {
  formURL: string
}

export function ActionNetworkForm(props: ActionNetworkFormProps) {
  return (
    <>
    <Script
    src={props.formURL}
    onLoad={() => {
        console.log('Script has loaded')
      }}/>
        <div id='can-form-area-powuk-vote'></div>    
        </>
  )
}